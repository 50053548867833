import Spinner from "./Spinner";
import cx from "classnames";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { forwardRef } from "react";

const buttonVariants = cva(
  [
    "cursor-pointer inline-flex items-center font-medium rounded-full disabled:opacity-50 transition shadow-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus:ring-opacity-50 disabled:opacity-50 disabled:pointer-events-none ring-offset-background",
  ],
  {
    variants: {
      variant: {
        primary:
          "bg-secondary focus:bg-primary focus:ring-primary hover:bg-primary text-white",
        secondary:
          "border border-secondary border-solid focus:bg-primary focus:border-primary focus:text-white hover:bg-primary hover:border-primary hover:text-white",
        dark: "align-baseline appearance-none bg-black focus:bg-teal-600 font-semibold hover:bg-teal-600 m-0 normal-case px-10 py-4 relative rounded-2xl text-white text-xl",
        outline: "border border-gray-200",
      },
      size: {
        xs: "py-0 px-2 gap-1 w-max",
        sm: "py-1 px-3 gap-2",
        md: "py-2 px-4 gap-3",
        lg: "py-4 px-7 gap-3",
      },
      justify: {
        center: "justify-center",
        start: "justify-start",
        between: "justify-between",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "lg",
      justify: "center",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      justify,
      asChild = false,
      loading,
      children,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={buttonVariants({ variant, size, justify, className })}
        ref={ref}
        disabled={loading}
        type="button"
        {...props}
      >
        {loading ? <Spinner /> : children}
      </Comp>
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
export default Button;
