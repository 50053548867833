import { User } from "next-auth";
import { useSession } from "next-auth/react";
import Link from "next/link";
import useRequiredSessionStore from "../lib/hooks/useRequiredSessionStore";

interface FooterProps {
  className?: string;
}
export default function Footer(props: FooterProps) {
  const session = useRequiredSessionStore();
  const user = session.data?.user;

  const { className = "" } = props;

  return (
    <footer
      className={`
      bg-white
      relative
      before:block
      before:content-[" "]
      before:h-2
      before:w-full
      before:bottom-0
      before:bg-gradient-to-r
      before:from-grad-quaternary
      before:to-grad-secondary
      print:hidden
      ${className}
    `}
    >
      <div
        className="
        px-6
        sm:px-10
        pt-16
        pb-20
        flex
        flex-wrap
        justify-center
        gap-24
        text-gray-700
      "
      >
        <a href="https://equalcare.coop" target="blank">
          equalcare.coop
        </a>

        {user?.id && (
          <>
            <Link href="/policies">
              <a>Policies</a>
            </Link>
            <Link href="/policies">
              <a>Emergency Contacts</a>
            </Link>
          </>
        )}
      </div>
    </footer>
  );
}
